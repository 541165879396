<template>
  <div id="app">
    <router-view />

    <!-- 无权限提示 -->
    <el-dialog
      class="no-permission-dialog"
      title="无权限"
      :visible.sync="noPermission"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :style="{ margin: '0', padding: '0', height: '100vh' }"
      :modal="true"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 20px;
          color: #fff;
        "
      >
        <h3 style="font-size: 2em; font-weight: bold; color: #000">
          无此页面访问权限
        </h3>
        <p style="font-size: 1.2em; margin-top: 20px; color: #000">
          您没有权限访问此页面，请联系管理员。
        </p>

        <!-- 图标和提示 -->
        <div style="margin-top: 30px; font-size: 2em">
          <i class="el-icon-lock" style="font-size: 50px; color: #000"></i>
        </div>
        <p
          style="
            background: none;
            height: 5%;
            position: fixed;
            bottom: 0px;
            width: 100%;
            text-align: center;
          "
        >
          <a
            class="banquan"
            style="color: #000"
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >浙ICP备2024138720号-1</a
          >
        </p>
      </div>
    </el-dialog>

    <el-dialog
      title="绑定手机"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :style="{ zIndex: 3000 }"
    >
      <el-form ref="dataForm" :model="bindPhoneVo" size="small">
        <h4>绑定你的手机号，建立通用智能系统联系</h4>
        <h4>
          此系统为温州市通用锁具公司服务,绑定需要提前联系管理员进行后台录入账号信息
        </h4>
        <el-form-item label="手机号码">
          <el-input v-model="bindPhoneVo.phone" />
        </el-form-item>

        <!-- 验证码输入框 -->
        <el-form-item label="验证码">
          <el-row :gutter="10">
            <el-col :span="16">
              <el-input
                v-model="bindPhoneVo.autoCode"
                placeholder="请输入验证码"
              />
            </el-col>
            <el-col :span="8">
              <el-button
                :disabled="sendCodeDisabled"
                @click="sendCode"
                size="small"
              >
                {{ sendCodeText }}
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          icon="el-icon-check"
          @click="saveBind()"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import userInfoApi from '@/api/userInfo'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      show: true,
      dialogVisible: false,

      bindPhoneVo: {
        openId: '',
        phone: '',
        autoCode: '', // 验证码
      },
      sendCodeText: '发送验证码', // 按钮文字
      sendCodeDisabled: false, // 按钮是否禁用
      countdown: 60, // 倒计时秒数
    }
  },

  created() {
    // 处理微信授权登录
    this.wechatLogin()
    // 添加事件监听，阻止页面刷新或关闭
    window.addEventListener('beforeunload', this.preventExit)
  },

  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('beforeunload', this.preventExit)
  },
  computed: {
    ...mapState({
      noPermission: 'noPermission', // 获取 Vuex 中的 noPermission 状态
    }),
  },

  methods: {
    preventExit(event) {
      if (!this.dialogVisible) return // 如果未显示绑定弹框，不阻止
      event.preventDefault()
      event.returnValue = '您还未完成绑定操作，确定要离开吗？'
    },

    // 发送验证码
    sendCode() {
      if (!this.bindPhoneVo.phone) {
        this.$message.error('请先输入手机号码！')
        return
      }
      if (this.bindPhoneVo.phone.length != 11) {
        this.$message.error('手机号格式不正确!')
        return
      }
      userInfoApi
        .sendAutoCordSms(this.bindPhoneVo.phone)
        .then((response) => {
          this.$message.success('验证码已发送！')
          this.startCountdown() // 开始倒计时
        })
        .catch((error) => {
          this.$message.error(error.data.data)
        })
    },

    // 倒计时功能
    startCountdown() {
      this.sendCodeDisabled = true
      this.sendCodeText = `${this.countdown}秒后重发`

      const timer = setInterval(() => {
        this.countdown--
        this.sendCodeText = `${this.countdown}秒后重发`

        if (this.countdown <= 0) {
          clearInterval(timer)
          this.sendCodeDisabled = false
          this.sendCodeText = '发送验证码'
          this.countdown = 60 // 重置倒计时
        }
      }, 1000)
    },

    clear() {
      window.localStorage.setItem('token', '')
      this.bindPhoneVo = {}
    },
    wechatLogin() {
      // 处理微信授权登录
      let token = this.getQueryString('token') || ''
      let openId = this.getQueryString('openId') || ''
      // token === '' && openId != '' 只要这种情况，未绑定账号
      if (token === '' && openId != '') {
        // 绑定账号
        this.bindPhoneVo.openId = openId
        this.dialogVisible = true
        // alert('进入绑定手机判断')
      } else {
        // 如果绑定了，授权登录直接返回token
        if (token !== '') {
          window.localStorage.setItem('token', token)
        }
        token = window.localStorage.getItem('token') || ''
        if (token == '') {
          let url = window.location.href.replace('#', 'guiguoa')
          window.location =
            'https://work.lockpy.vip/admin/wechat/authorize?returnUrl=' + url
          // 'http://work.xiaoyanglife.cn/admin/wechat/authorize?returnUrl=' + url
        }
      }
    },

    saveBind() {
      if (this.bindPhoneVo.phone.length != 11) {
        this.$message.error('手机号格式不正确!')

        return
      }

      if (!this.bindPhoneVo.autoCode || this.bindPhoneVo.autoCode.length != 6) {
        this.$message.error('验证码格式不正确!')

        return
      }

      userInfoApi
        .bindPhone(this.bindPhoneVo)
        .then((response) => {
          // 校验正常
          window.localStorage.setItem('token', response.data)
          this.dialogVisible = false
          // 解除页面刷新限制
          window.removeEventListener('beforeunload', this.preventExit)
          // 刷新当前页面
          // window.location.href = window.location.href
        })
        .catch((error) => {
          this.$message.error(error.data.data)
        })
    },

    getQueryString(paramName) {
      if (window.location.href.indexOf('?') == -1) return ''

      let searchString = window.location.href.split('?')[1]
      let i,
        val,
        params = searchString.split('&')

      for (i = 0; i < params.length; i++) {
        val = params[i].split('=')
        if (val[0] == paramName) {
          return val[1]
        }
      }
      return ''
    },
  },
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

/* 设置el-dialog的样式 */
.no-permission-dialog .el-dialog__wrapper {
  z-index: 9999; /* 增加层级 */
  position: fixed; /* 确保弹框定位到固定位置 */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 !important; /* 避免任何默认的边距 */
}

.no-permission-dialog .el-dialog {
  max-width: none;
  margin: 0 !important;
  padding: 0;
  width: 100%;
  height: 100vh;
  margin-top: 0vh !important; /* 强制覆盖原有样式 */
}

.no-permission-dialog .el-dialog__body {
  padding: 0;
}

.no-permission-dialog .el-dialog__header {
  display: none; /* 隐藏标题 */
}
</style>
